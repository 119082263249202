import { ClerkOptions } from "@clerk/types"
import { theme } from "~/utils/theme"

export const clerkOptions: ClerkOptions = {
  appearance: {
    layout: {
      logoPlacement: "none",
    },
    variables: {
      colorPrimary: theme.colors.black,
    },
    elements: {
      headerTitle: {
        display: "none",
      },
      headerSubtitle: {
        display: "none",
      },
      rootBox: {
        width: "100%",
        overflow: "hidden",
      },
      card: {
        margin: 0,
        width: "100%",
        boxShadow: "none",
        padding: 0,
        gap: theme.space[4],
      },
      formFieldLabel: {
        color: theme.colors.gray[400],
        fontWeight: 600,
      },
      formFieldInput: {
        borderRadius: theme.radii.md,
        padding: theme.space[3],
        fontSize: "14px",
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 32,
      },
      formButtonPrimary: {
        borderRadius: theme.radii.md,
        fontSize: "14px",
        textTransform: "none",
      },
      footer: {
        fontSize: "14px",
      },
      footerActionText: {
        fontSize: "inherit",
      },
      footerActionLink: {
        fontSize: "inherit",
        color: theme.colors.red[400],
      },
    },
  },
}
